import LazyImg from "@/Components/LazyImg";
import Modal from "@/Components/Modal";
import { Button } from "@/Components/ui/button";
import { Card } from "@/Components/ui/card";
import { ScrollArea } from "@/Components/ui/scroll-area";
import { cn, lang } from "@/Hooks/Functions";
import { JSONGetRequest } from "@/Hooks/JSONRequest";
import Loader from "@/Modules/Loader";
import { Icon } from "@iconify/react";
import { usePage } from "@inertiajs/react";
import { bothAction, bothState } from "@state/mode-both";
import { useDebounceFn } from "ahooks";
import { useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useSnapshot } from "valtio";
import { subscribeKey } from "valtio/utils";

export default function ProductSelectModal({
    show = false,
    onClose = () => { },
    onSelect = () => { },
}) {
    const { productcategories } = usePage().props;

    const snap = useSnapshot(bothState);

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const { run } = useDebounceFn((category_id) => {
        JSONGetRequest({
            href: route("products.paginate", {
                per_page: 20,
                category_id: category_id
            }),
            onResult: (val) => setItems(val.data),
            onLoading: (val) => setLoading(val),
        });
    }, { wait: 500 });

    subscribeKey(bothState, 'selectedProductCategory', (val) => run(val));

    useEffect(() => {
        run(snap.selectedProductCategory);
    }, [])

    return (
        <Modal className="max-w-7xl" closable={!snap.predesignsModalForce} show={show} onClose={onClose} title="Select Product">
            <div className="w-full grid grid-cols-5 gap-2 text-foreground">
                <div className="col-span-1 min-h-[70vh] flex flex-col gap-2 p-2  text-white">
                    <Button size="sm" variant="link" className={cn("w-full inline-flex justify-start", 'all' === snap.selectedProductCategory ? '!font-semibold text-teal-600' : '')} onClick={() => bothAction.setBothState('selectedProductCategory', 'all')}>
                        {lang("All Categories")}
                    </Button>
                    {productcategories?.length > 0 &&
                        productcategories.map((cat) =>
                            <Button variant="link" size="sm" onClick={() => bothAction.setBothState('selectedProductCategory', cat.id)}
                                key={cat.id}
                                className={cn("w-full inline-flex justify-start", cat.id === snap.selectedProductCategory ? '!font-semibold text-teal-600' : '')}
                            >
                                {lang(cat.name)}
                            </Button>
                        )}
                </div>
                <div className="col-span-4 flex flex-col justify-start items-center p-4 max-h-[70vh]">
                    {loading && <Loader className="w-full min-h-[50vh] h-full" />}
                    {!loading && items?.length === 0 && <div className="!mx-auto min-h-[50vh] flex flex-col justify-center items-center gap-2 aspect-auto">
                        <Icon icon="mdi:package-variant-closed" height={100} className="text-muted-foreground" />
                        <span className="text-xl font-semibold">No filtered product found.</span>
                    </div>}

                    <ScrollArea className="w-full pr-3">
                        <ResponsiveMasonry
                            columnsCountBreakPoints={{
                                350: 1,
                                750: 2,
                                900: 3,
                                1024: 4
                            }}
                        >
                            <Masonry gutter="0.5em">
                                {items.map((i) => (
                                    <Card
                                        key={i.id}
                                        className="flex border relative flex-col justify-center overflow-hidden items-center gap-2 group"
                                    >
                                        <LazyImg src={i.url_thumb}
                                            className="min-h-[100px] max-h-[300px] group-hover:scale-105 col-span-1 bg-white relative bg-cover bg-no-repeat bg-center flex flex-col justify-center items-center"
                                        />
                                        <p className="p-2 w-full text-sm font-semibold">
                                            {i.title}
                                        </p>
                                        <div className="flex flex-row justify-between items-center w-full p-2 gap-4">
                                            <span className="text-xs font-semibold text-muted-foreground">
                                                {i.range_price}
                                            </span>
                                            <Button size="xs" onClick={() => onSelect(i)} >
                                                Select
                                            </Button>
                                        </div>
                                    </Card>
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>
                    </ScrollArea>
                </div>

            </div>
        </Modal >
    );
}
